export const ROUTE_DASHBOARD = '/dashboard'
export const ROUTE_WELCOME = '/welcome'
export const ROUTE_LOGIN = '/login'
export const ROUTE_REGISTRATION = '/registration'
export const ROUTE_INVOICES = '/invoices/list'
export const ROUTE_INVOICES_APPLICATIONS = '/invoices/applications'
export const ROUTE_INVOICES_APPLICATIONS_CREATE = '/invoices/applications/create'
export const ROUTE_INVOICES_APPLICATIONS_SUCCESS = '/invoices/applications/success'
export const ROUTE_SUPPLIER = '/supplier'
export const ROUTE_SUPPLIER_RESPONSIBLE = '/supplier?tab=responsible_data'
export const ROUTE_SUPPLIER_COMPANY = '/supplier?tab=company_data'
export const ROUTE_SUPPLIER_CHANGE_PASSWORD = '/supplier?tab=change_password'
export const ROUTE_ANTICIPATIONS = '/anticipations'
export const ROUTE_LOGOUT = '/logout'
export const ROUTE_ANTICIPATION_REVIEW = '/anticipation-review'
export const ROUTE_ANTICIPATION_SUCCESS = '/anticipation-success'
export const ROUTE_REFERRAL_LANDING = '/referral'
export const ROUTE_REFERRAL_ACHIEVEMENTS = '/referral/achievements'
export const ROUTE_DIGITAL_ACCOUNT_ONBOARDING = '/digital-account/onboarding'
export const ROUTE_DIGITAL_ACCOUNT_LANDING_PAGE = '/digital-account/landing-page'
export const ROUTE_DIGITAL_ACCOUNT_LOGIN = '/digital-account/login'
export const ROUTE_DIGITAL_ACCOUNT_TRANSFER = '/digital-account/core/transfer'
export const ROUTE_DIGITAL_ACCOUNT_TRANSFER_TED = '/digital-account/core/transfer/ted'
export const ROUTE_DIGITAL_ACCOUNT_TRANSFER_PIX = '/digital-account/core/transfer/pix'
export const ROUTE_DIGITAL_ACCOUNT_TRANSFER_PIX_COPY_AND_PASTE =
    '/digital-account/core/transfer/pix/copy-and-paste'
export const ROUTE_DIGITAL_ACCOUNT_BILLING = '/digital-account/core/billing'
export const ROUTE_DIGITAL_ACCOUNT_BILLING_REPORT =
    '/digital-account/core/billing?action=billing-report'
export const ROUTE_DIGITAL_ACCOUNT_BILLING_PIX = '/digital-account/core/billing/pix'
export const ROUTE_DIGITAL_ACCOUNT_DASHBOARD = '/digital-account/core/dashboard'
export const ROUTE_DIGITAL_ACCOUNT_PAYMENTS = '/digital-account/core/payments'
export const ROUTE_DIGITAL_ACCOUNT_LOGOUT = '/digital-account/logout'
export const ROUTE_DIGITAL_ACCOUNT_SETTINGS = '/digital-account/core/supplier'
export const ROUTE_DIGITAL_ACCOUNT_STATEMENTS = '/digital-account/core/statement'
export const ROUTE_DIGITAL_ACCOUNT_PIX_KEYS = '/digital-account/core/pix/keys'
export const ROUTE_DIGITAL_ACCOUNT_PIX_SCHEDULE = '/digital-account/core/pix/schedules'
export const ROUTE_DIGITAL_ACCOUNT_HELP_CENTER = '/digital-account/core/help-center'
export const ROUTE_DIGITAL_ACCOUNT_TERMS = '/digital-account/core/terms-of-use'
export const ROUTE_DIGITAL_ACCOUNT_LIMITS = '/digital-account/core/limits'
export const ROUTE_DIGITAL_ACCOUNT_CLOSE = '/digital-account/core/close'
